/* build your own custom Bootstrap theme:
   https://themestr.app
*/
.newEl {
     
    border-radius: 17px;
    border: 0px solid rgb(0, 0, 0);
    box-shadow: rgba(0, 0, 0, 0.2) 5px 5px 5px;
    color: black; 
    font-family: arial, sans-serif; 
    font-size: 18px; 
    font-stretch: normal;
    font-style: normal; 
    font-variant: normal; 
    line-height: normal;
    margin: 0px auto; 
    margin-top:9px;
    padding: 4px 5px 3px;
    width: 82%;

}
.newSp{
    margin-top:10px ;
    font-size:22px;
    text-shadow: 1px 1px 3px #FFEB3B, 1px 1px 3px #FFEB3B, -1px -1px 3px #FFEB3B, -1px -1px 3px #FFEB3B;
    color:#E91E63;
} 
.animate-charcter
{
   text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #231557 0%,
    #44107a 29%,
    #ff1361 67%,
    #fff800 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  /* text-fill-color: transparent; */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
      font-size: 50px;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

.footerBtn{
    display: block;
    line-height: 20px;
    position: fixed;
    left: 0px;
    bottom: 10px;
    height: 40px;
    border-radius: 15px;
    box-sizing: border-box;
    padding: 5px;
    background: #34af23;
    color: #ffffff;
    font-size: 22px;
    text-align: center;
    text-decoration: none;
    width: 95%;
    margin-left: 10px;
    margin-right: 30px;


}